<script setup lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue"
import CheckIcon from "@/assets/icons/check.svg?component"
import CaretDownIcon from "@/assets/icons/caret-down.svg?component"
import InfoCircleIcon from "@/assets/icons/info-circle-solid.svg?component"

// TODO: support array options
// type ArrayOption = [string | number, string | number]

interface SimpleObjectOption {
  id: string | number
  name: string | number
  avatar?: string
}

export type SelectOption = SimpleObjectOption | string | number

interface Props {
  modelValue?: string
  groupPosition?: "left" | "middle" | "right" | undefined
  description?: string | undefined // hint text
  disabled?: boolean
  errorMessage?: string | undefined
  invalid?: boolean
  label?: string | undefined
  optional?: boolean
  options?: SelectOption[]
  placeholder?: string
  size?: "small" | "medium" | "large"
  tooltip?: string | undefined // adds tooltip to description
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: "",
  groupPosition: undefined,
  description: undefined,
  disabled: false,
  errorMessage: undefined,
  invalid: false,
  label: undefined,
  optional: false,
  options: () => [],
  placeholder: "",
  size: "medium",
  tooltip: undefined,
})

const emit = defineEmits(["update", "update:modelValue"])

const listboxButtonHeightClasses = {
  small: "h-6",
  medium: "h-8",
  large: "h-12",
}

const listboxButtonPaddingClasses = {
  small: "pl-2 pr-10 py-0",
  medium: "pl-3 pr-10 py-2",
  large: "pl-4 pr-10 py-3",
}

const listboxButtonAvatarPaddingClasses = {
  small: "pl-1 pr-10 py-0",
  medium: "pl-1.5 pr-10 py-0",
  large: "pl-2 pr-10 py-0",
}

const listboxButtonAvatarSizeClasses = {
  small: "h-4 w-4 mr-2 rounded-sm",
  medium: "h-5 w-5 mr-1.5 rounded-sm",
  large: "h-8 w-8 mr-2 rounded",
}

const listboxButtonTextSizeClasses: {
  [K in NonNullable<Props["size"]>]: string
} = {
  small: "text-xs",
  medium: "text-xs",
  large: "text-m",
}

const listboxOptionSizeClasses: { [K in NonNullable<Props["size"]>]: string } =
  {
    small: "py-1.5 pl-2 pr-10",
    medium: "py-2 pl-3 pr-10",
    large: "py-3 pl-4 pr-10",
  }

const listboxOptionAvatarSizeClasses: {
  [K in NonNullable<Props["size"]>]: string
} = {
  small: "py-1.5 pl-1.5 pr-10",
  medium: "py-1.5 pl-1.5 pr-10",
  large: "py-2 pl-2 pr-10",
}

const listboxOptionTextSizeClasses: {
  [K in NonNullable<Props["size"]>]: string
} = {
  small: "text-xs",
  medium: "text-xs",
  large: "text-m",
}

const groupPositionClasses: {
  [K in NonNullable<Props["groupPosition"]>]: string
} = {
  left: "rounded-l border-r-0",
  middle: "rounded-none border-x-0",
  right: "rounded-r border-l-0",
}

// convert any of our accepted input types for options to objects
const formattedOptions = computed(() => {
  return props.options.map((option: SelectOption) => {
    if (Array.isArray(option)) throw new Error("Arrays aren't supported yet")
    if (option !== null && typeof option === "object") return option
    return { id: option, name: option } as SimpleObjectOption
  })
})

const listboxButtonClasses = computed(() => {
  const listboxButtonClasses = [listboxButtonHeightClasses[props.size]]

  if (selectedOption.value?.avatar) {
    listboxButtonClasses.push(listboxButtonAvatarPaddingClasses[props.size])
  } else {
    listboxButtonClasses.push(listboxButtonPaddingClasses[props.size])
  }

  if (props.disabled) {
    listboxButtonClasses.push(
      "opacity-30 cursor-not-allowed focus:outline-none"
    )
  }
  if (props.invalid) {
    listboxButtonClasses.push("border-red-500 bg-red-25")
  }
  if (props.groupPosition) {
    listboxButtonClasses.push(groupPositionClasses[props.groupPosition])
  } else {
    listboxButtonClasses.push("rounded focus:ring-2 focus:ring-indigo-600")
  }

  return listboxButtonClasses.join(" ")
})

const listboxButtonTextClasses = computed(() => {
  return listboxButtonTextSizeClasses[props.size]
})

const listboxButtonAvatarClass = computed(() => {
  return listboxButtonAvatarSizeClasses[props.size]
})

const listboxOptionTextClasses = computed(() => {
  return listboxOptionTextSizeClasses[props.size]
})

const selectedOption = computed({
  get: () => formattedOptions.value.find((o) => o.id === props.modelValue),
  set: (value) => {
    emit("update", value?.id)
    emit("update:modelValue", value?.id)
  },
})

function listboxOptionClasses(
  option: SimpleObjectOption,
  active: boolean,
  selected: boolean
) {
  let stateClasses = "text-slate-600" // default
  if (active) stateClasses = "bg-indigo-50 text-indigo-600" // hover state
  if (selected) stateClasses = "bg-indigo-600 text-white" // highest priority state
  return [
    stateClasses,
    option.avatar
      ? listboxOptionAvatarSizeClasses[props.size]
      : listboxOptionSizeClasses[props.size],
  ].join(" ")
}
</script>

<template>
  <Listbox v-model="selectedOption" as="div" :disabled="props.disabled">
    <ListboxLabel
      class="block text-sm font-medium leading-6 text-slate-600"
      :class="{ 'opacity-30': props.disabled }"
    >
      {{ label }}
      <span
        v-if="optional"
        class="text-xxs font-semibold text-slate-300 uppercase"
      >
        Optional
      </span>
    </ListboxLabel>
    <p
      v-if="description"
      class="text-sm text-slate-400 mb-2"
      :class="{ 'opacity-30': props.disabled }"
    >
      {{ description }}
      <VTooltip v-if="tooltip" :text="props.tooltip">
        <InfoCircleIcon
          class="w-3 h-3 inline text-grey-400"
          aria-hidden="true"
        />
      </VTooltip>
    </p>
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default border bg-white text-left text-slate-600 border-slate-200 focus:outline-none"
        :class="listboxButtonClasses"
      >
        <span
          v-if="!selectedOption"
          :class="listboxButtonTextClasses"
          class="block opacity-50 truncate font-medium leading-none"
        >
          {{ placeholder || "&nbsp;" }}
        </span>
        <div v-if="selectedOption" class="flex items-center">
          <img
            v-if="selectedOption.avatar"
            :src="selectedOption.avatar"
            alt=""
            :class="listboxButtonAvatarClass"
          />
          <span
            :class="listboxButtonTextClasses"
            class="block truncate py-px font-medium leading-none"
          >
            {{ selectedOption.name }}
          </span>
        </div>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <CaretDownIcon class="h-4 w-4 text-slate-700" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <ListboxOption
            v-for="option in formattedOptions"
            :key="option.id"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
          >
            <li
              :class="listboxOptionClasses(option, active, selected)"
              class="relative cursor-pointer select-none flex items-center"
            >
              <img
                v-if="option.avatar"
                :src="option.avatar"
                alt=""
                class="h-6 w-6 mr-2 rounded-sm"
              />
              <span
                :class="[
                  listboxOptionTextClasses,
                  selected ? 'bg-indigo-600 text-white' : `text-slate-600`,
                ]"
                class="block truncate py-px font-medium leading-none"
              >
                {{ option.name }}
              </span>

              <span
                v-if="selected"
                class="text-white absolute inset-y-0 right-0 flex items-center pr-4"
              >
                <CheckIcon class="h-4 w-4" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>

      <div class="absolute -bottom-5 text-xs text-red-500 font-medium">
        {{ errorMessage }}
      </div>
    </div>
  </Listbox>
</template>
