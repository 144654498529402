import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.83 8.522a1.09 1.09 0 0 0-1.516 0 1.026 1.026 0 0 0 0 1.478l3.79 3.694a1.09 1.09 0 0 0 1.517 0l6.065-5.91a1.026 1.026 0 0 0 0-1.478 1.09 1.09 0 0 0-1.516 0l-5.307 5.172z" }, null, -1)
  ])))
}
export default { render: render }