import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10.65 13.226a.91.91 0 0 1-1.3 0L5.236 8.933c-.524-.547-.122-1.433.65-1.433h8.226c.772 0 1.174.886.65 1.433z" }, null, -1)
  ])))
}
export default { render: render }